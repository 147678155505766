import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeDa from '@angular/common/locales/da';
import { AuthService } from './services/auth.service';
import { PermissionsService } from './services/permissions.service';
import { StatusPageService } from './services/status-page.service';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { InstanaErrorHandler } from './instana-error-handler';
import { AppService } from './services/app-service';
import { ConfigitApiService } from './services/configit-api.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DswFooterComponent } from '@vi-succ/design-system-web';

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEn, 'en-DE');
registerLocaleData(localeDe, 'de-CH');
registerLocaleData(localeDe, 'de-AT');
registerLocaleData(localeFr, 'fr-CH');
registerLocaleData(localeIt, 'it-CH');
registerLocaleData(localeFr, 'fr-BE');
registerLocaleData(localeNl, 'nl-BE');
registerLocaleData(localeDe, 'de-NL');
registerLocaleData(localeNl, 'nl-NL');
registerLocaleData(localeDa, 'da-DK');

export function createTranslateLoader(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: environment.http.translations,
            suffix: '/download?file_format=i18next',
        },
        {
            prefix: environment.http.dswTranslations,
            suffix: '/download?file_format=simple_json',
        },
    ]);
}

export class DynamicLocaleId extends String {
    constructor(protected service: TranslateService) {
        super();
    }

    toString() {
        return this.service.currentLang;
    }
}

function initializeApp(
    auth: AuthService,
    permissions: PermissionsService,
    statusPage: StatusPageService,
    api: ConfigitApiService,
    appService: AppService
): () => Promise<any> {
    return () =>
        statusPage
            .checkForDowntime()
            .pipe(
                map((down) => !down),
                filter(Boolean),
                concatMap(() => {
                    auth.init();
                    return forkJoin([permissions.getLoggedInUserInfo(), api.getSettings()]);
                }),
                map(([userInfo, settings]) => {
                    const embedded = getParamsHelper('embedded') === 'true';
                    const authorized = permissions.checkIsUserAuthorized(userInfo, embedded);
                    return authorized ? { userInfo, settings } : undefined;
                }),
                filter(Boolean), // stop here if user is not authorized for app
                concatMap(({ userInfo, settings }) =>
                    forkJoin([of(userInfo), of(settings), permissions.fetchSalesOrgInfo(userInfo)])
                ),
                tap(([userInfo, settings, salesOrgInfo]) => {
                    appService.init(settings, userInfo, salesOrgInfo);
                })
            )
            .toPromise();
}

function getParamsHelper(key: string): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend],
            },
        }),
        FlexLayoutModule,
        DswFooterComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useClass: DynamicLocaleId,
            deps: [TranslateService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AuthService, PermissionsService, StatusPageService, ConfigitApiService, AppService],
            multi: true,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'l',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'L',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
